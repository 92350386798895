import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import WatchElementAnimate from '../../components/watch-element-animate'
import LocalizedLink from '../../components/localized-link'
const KeyBenefitsWrapper = styled.div``

const KeyBenefits = ({ benefits, items, i18n }) => {
  return (
    <WatchElementAnimate
      className={'.solutions__industries__animated'}
      animateName={'animate__fadeInUp'}>
      <KeyBenefitsWrapper
        tw="container pt-16 pb-8 text-center md:pt-28 md:pb-14 relative"
        className="solutions__industries__animated">
        <h2 tw="w-64 pb-4 m-auto md:w-auto text-navy">
          {benefits.headline.text}
        </h2>
        <p tw="text-xl text-ocean max-w-xl m-auto md:mt-1">
          {benefits.subheadline.text}
        </p>
        <div tw="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
          {items.map((item, key) => {
            return (
              <div key={key} tw="md:text-left px-8 py-8 z-10 bg-white">
                <div tw="w-8 pb-4 m-auto md:ml-0">
                  <Img fluid={item.card_icon.fluid}></Img>
                </div>
                <div>
                  <h4 tw="mb-4 text-navy">{item.card_headline}</h4>
                  <p tw="mb-4 text-lg text-ocean">{item.card_description}</p>
                  {item.learn_more &&
                  !item.learn_more.hasOwnProperty('url') ? (
                    <LocalizedLink
                      to={item.learn_more}
                      tw="text-turquoise text-lg font-bold">
                      {i18n.buttons.learn_more}
                    </LocalizedLink>
                  ) : false}
                </div>
              </div>
            )
          })}
        </div>
      </KeyBenefitsWrapper>
    </WatchElementAnimate>
  )
}
export default KeyBenefits
