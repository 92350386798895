import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'

const ApproachWrapper = styled.div`
  .approach-image {
    margin-top: -100px;
    margin-bottom: -170px;
    @media (max-width: 640px) {
      margin-top: 0;
      margin-bottom: -160px;
    }
  }
`

const Approach = ({ approach, items }) => {
  return (
    <ApproachWrapper tw="pt-8 pb-8 text-center md:pt-14 md:pb-14">
      <div tw="container">
        <h2 tw="text-navy pb-4">{approach.headline.text}</h2>
        <p tw="text-xl text-ocean max-w-xl m-auto">
          {approach.subheadline.text}
        </p>
      </div>
      <div className="approach-image">
        <Img fluid={approach.image.fluid} tw="hidden md:block"></Img>
        <Img fluid={approach.mobile_image.fluid} tw="md:hidden"></Img>
      </div>
      <div tw="container md:mb-4">
        <div tw="grid md:grid-cols-3 gap-8 md:text-left">
          {items.map((item) => {
            return (
              <div>
                <h4 tw="text-navy">{item.approach_headline}</h4>
                <p tw="text-ocean text-lg mt-3">{item.approach_description}</p>
              </div>
            )
          })}
        </div>
      </div>
    </ApproachWrapper>
  )
}
export default Approach
